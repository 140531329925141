/* eslint-disable @nx/enforce-module-boundaries */
import { Box, HStack } from '@mybridge/ui';
import { Image } from '@mybridge/ui/image';
import { VideoPlayer } from '@mybridge/ui/video-player';
import { useContext, useEffect, useRef, useState } from 'react';
import { Navigation } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import { PostBoxContext } from '../context';
import styles from './media-player.module.scss';

export const ModalMediaDisplay = ({ fullScreen = false }) => {
  const { post } = useContext(PostBoxContext);
  const { gif, media } = post;
  const imgRef = useRef();
  const gifRef = useRef();

  const [images_, setImages_] = useState([]);
  const [videos_, setVideos_] = useState([]);
  const [gifs_, setGifs_] = useState([]);

  useEffect(() => {
    prepareMedia();
  }, [media]);

  useEffect(() => {
    prepareGifs();
  }, [gif]);

  const prepareMedia = async () => {
    const vids = [];
    const imgs = [];
    for (let m of media ?? []) {
      const { file } = m ?? {};
      if (
        file?.toLowerCase?.()?.includes?.('.mp4') ||
        file?.toLowerCase?.()?.includes?.('.mov')
      ) {
        vids.push(file);
      } else {
        imgs.push(file);
      }
    }
    setVideos_(vids);
    setImages_(imgs);
  };

  const prepareGifs = async () => {
    const gs = [];
    if (gif?.length) {
      gs.push(gif);
    }
    setGifs_(gs);
  };

  useEffect(() => {
    adjustHeight(imgRef);
  }, [imgRef?.current]);

  useEffect(() => {
    adjustHeight(gifRef);
  }, [gifRef?.current]);

  const adjustHeight = (ref) => {
    if (ref.current) {
      const parentRect = ref?.current?.parentElement?.getBoundingClientRect?.();
      ref.current.style.objectFit = 'contain';

      console.warn('maxh', ref, window.innerHeight);
      if (ref.current.height >= parentRect?.height) {
        ref.current.style.width = 'auto';
        ref.current.style.height = parentRect?.height + "px";
        // ref.current.style.maxHeight = '100vh';
      }

      if (ref.current.width >= parentRect?.width) {
        ref.current.style.height = 'auto';
        ref.current.style.width = parentRect?.width + "px";
      }
    }
  };

  return (
    <>
      {videos_?.length ? (
        <VideoPlayer
          wrapperProps={{ height: '100%', width: '100%' }}
          src={videos_?.[0]}
          height="100%"
          width="100%"
          style={{
            backgroundColor: 'black',
            maxHeight: '100vh',
            width: '100%',
            height: '100%',
          }}
          className={styles.mediaPlayer}
        />
      ) : (
        <>
          {images_?.length ? (
            images_.length > 1 ? (
              <Swiper navigation modules={[Navigation]} slidesPerView={1}>
                {images_?.map?.((img, index) => (
                  <SwiperSlide key={index}>
                    <HStack
                      userSelect="none"
                      h="100vh"
                      alignItems="center"
                      overflowY="auto"
                    >
                      <Image w="100%" maxH="100vh" src={img} key={index} style={{ objectFit: 'contain' }} />
                    </HStack>
                  </SwiperSlide>
                ))}
              </Swiper>
            ) : fullScreen ? (
              <>
                <Image key={0} maxH="100vh" w="100%" src={images_?.[0]} style={{ objectFit: 'contain' }} />
              </>
            ) : (
              <>
                <Image key={1} ref={imgRef} h="100vh" w="100%" src={images_?.[0]} style={{ objectFit: 'contain' }}/>
              </>
            )
          ) : (
            <>
              {gifs_?.length ? (
                fullScreen ? (
                  <Image key="0" h="100vh" src={gifs_?.[0]} />
                ) : (
                  <Image key="1" ref={gifRef} w="100%" src={gifs_?.[0]} />
                )
              ) : (
                ''
              )}
            </>
          )}
        </>
      )}
    </>
  );
};
